<template>
    <div>
        <md-button @click="charge">Pay Charge</md-button>
        <md-button @click="refund">Pay Refund</md-button>
        <div></div>
        <md-button @click="send">send email immediate</md-button>
        <h1>Account Onboarding</h1>
        <div id="container"></div>
        <div id="error" hidden>Something went wrong!</div>
    </div>
</template>

<script>
import { loadConnect } from "@stripe/connect-js";
export default {
    data: () => ({
        mail: "",
        mailValid: false,
        invalidMessage: "Invalid mail"
    }),
    methods: {
        async charge() {
            await this.$store.dispatch("crud/post", { api: "test/pay-charge" });
        },
        async refund() {
            await this.$store.dispatch("crud/post", { api: "test/pay-refund" });
        },
        async send() {
            // let result = await this.$store.dispatch("crud/post", {
            //     api: "stripe/session"
            // });
            // this.initConnectComponent();
        },
        async initConnectComponent() {
            const fetchClientSecret = async () => {
                // Fetch the AccountSession client secret
                const { client_secret } = await this.$store.dispatch("crud/post", {
                    api: "stripe/session/acct_1O4e1BDF7ZonBH2E"
                });
                if (!client_secret) {
                    document.querySelector("#error").removeAttribute("hidden");
                    return undefined;
                } else {
                    document.querySelector("#error").setAttribute("hidden", "");
                    return client_secret;
                }
            };
            const clientSecret = await fetchClientSecret();
            const stripeConnect = await loadConnect();
            if (clientSecret) {
                const connectInstance = stripeConnect.initialize({
                    // This is your test publishable API key.
                    publishableKey: "pk_test_A4LnhBaJemZ4nOK5bXKKQmFC",
                    clientSecret,
                    // Returns a new client secret on long running sessions
                    // when the initially provided client secret might expire.
                    refreshClientSecret: fetchClientSecret,
                    appearance: {
                        overlays: "drawer"
                    },
                    locale: "en-US"
                });
                const onboardingComponent = connectInstance.create("account-onboarding");
                const container = document.getElementById("container");
                container.appendChild(onboardingComponent);
            }
        }
    },
    mounted() {
        // this.initConnectComponent();
    }
};
</script>
<!-- <template>
    <div>
        <h1>TEST</h1>
        <button @click="listMerchant">List Merchant</button>
    </div>
</template>
<script>
export default {
    methods: {
        async testApi() {
            this.$store.dispatch("crud/get", {
                api: "products",
                params: {
                    query: {
                        where: {
                            type: "Product"
                        }
                    }
                }
            });
        },
        listMerchant() {
            this.$store.dispatch("crud/get", {
                api: "EzTimePay/test"
            });
        }
    },
    created() {
        // this.testApi();
    }
};
</script> -->
